<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default{
  name:'app',
  data(){
    return{

    }
  },
  mounted(){
    this.$notify({
      title: this.$t('msg.note'),
      message: this.$t('msg.bei_jing_time'),
      duration: 0
    })
  }
}
</script>
